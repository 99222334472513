* {
  font-family: "Manrope", sans-serif !important;
}

a {
  text-decoration: none !important;
}

.my_profile-bg {
  width: 340px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.my_profile_user-img {
  border-radius: 100%;
  margin-left: -64px;
}

.property_details-img {
  width: 100%;
}

.notification-box {
  cursor: pointer;
  display: flex;
  width: 34px;
  height: 34px;
  border-radius: 100%;
  position: relative;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
}
.notification-box:hover {
  /* background: #0000ff6e; */
  background: #e6e4e4;
}

@media screen and (max-width: 900px) {
  .my_profile-bg {
    width: 100%;
    border-radius: 15px;
  }

  .my_profile_user-img {
    margin-left: 0px;
    margin-top: -64px;
  }

  .property_details-img {
    width: 100%;
    height: auto;
  }
}

.rentedPropertiesTable {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 0.3fr;
  margin-top: 5px;
  padding: 14px 22px;
  gap: 5px;
}

.rentProperty {
  display: grid;
  grid-template-columns: 1fr 0.8fr 1fr 1fr 0.3fr;
  padding: 14px 22px;
  align-self: center;
  align-items: center;
  border-top: 1px solid #bdbcbc;
  gap: 5px;
}

.spiningpage {
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.spinningLogo {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
